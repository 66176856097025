import {
  ChevronDown,
  ChevronRight,
} from "feather-icons-react/build/IconComponents";
import React, { useState, Fragment } from "react";

import ClockIcon from "../assets/icons/clock-icon.svg";
import MarkerIcon from "../assets/icons/marker-icon-azul.svg";
import TruckIcon from "../assets/icons/truck-icon.svg";
import { dispatchMessage } from "../utilities/dispatcherMessage";
import { formatPointToDispatch } from "../utilities/utilities";

const ListBox = ({ point, onClickTitle, agency }) => {
  // toggle content on click
  const [toggle, setToggle] = useState(false);

  const RenderSchelude = ({ point }) => {
    return (
      <Fragment>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="p-0-3rem"></th>
              <th className="p-0-3rem">
                <div className="text-center text-main d-flex gap-1 align-items-center fs-13px">
                  <ClockIcon />
                  Horario atención
                </div>
              </th>
              <th className="p-0-3rem">
                <div className="text-start text-main d-flex gap-1 align-items-center fs-13px">
                  <TruckIcon />
                  Recogida
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="fs-13px">
            {point.scheduleDetail.map((item, index) => (
              <tr key={index}>
                <td className="p-0-3rem">
                  <div className="text-end fw-semibold">{item.day}</div>
                </td>
                <td className="p-0-3rem">
                  <div className="text-center">
                    {item.open || item.close
                      ? item.open + " a " + item.close + " hrs."
                      : "Cerrado"}
                  </div>
                </td>
                <td className="p-0-3rem">
                  <div className="text-start">
                    {item.pickup ? (
                      item.pickup + " hrs."
                    ) : (
                      <div className="text-center">Sin retiro</div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex gap-2 my-4">
          <button className="btn btn-warning btn-options btn-lg w-100">
            <img src="/assets/directions-icon.svg" alt="map-icon" className="me-2" />
            Cómo llegar</button>
          <button className="btn btn-warning btn-options btn-lg w-100"><Share2 size={24} className="me-2" />Compartir</button>
        </div> */}
        <div>
          {/* {point.is_santiago && (
            <>
              <p className="fw-semibold">¿Qué es el horario de retiro?</p>
              <p className="fs-sm fst-italic">
                Entrega tus paquetes antes del horario de retiro y tus
                encomiendas serán despachadas el mismo día.
              </p>
            </>
          )} */}
          {/* <p className="fw-semibold">Servicios disponibles</p>
          <div>
            {point.schedule.services.isPackageAdmission && (
              <div className="d-flex gap-2 align-items-center">
                <CheckCircle size={20} className="text-hl" />
                <span className="fs-sm">Admisión de paquetes</span>
              </div>
            )}
            {point.schedule.services.isPackageDelivery && (
              <div className="d-flex gap-2 align-items-center">
                <CheckCircle size={20} className="text-hl" />
                <span className="fs-sm">Entrega de paquetes</span>
              </div>
            )}
            {point.schedule.services.isReturnOfPackages && (
              <div className="d-flex gap-2 align-items-center">
                <CheckCircle size={20} className="text-hl" />
                <span className="fs-sm">Devolución de paquetes</span>
              </div>
            )}
            
          </div> */}
        </div>
      </Fragment>
    );
  };

  const isPointTypeContainsCopec = point.point_type.description
    .toString()
    .toLowerCase()
    .includes("copec");

  return (
    <div
      className="list-box"
      onClick={(e) => {
        if (e.target.localName === "svg" || e.target.localName === "polyline") {
          return setToggle(!toggle);
        }
        onClickTitle(agency.agencyId);
        dispatchMessage("pudo:select", formatPointToDispatch(agency));
      }}
    >
      <div className="list-box-header mb-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start gap-2 align-items-start">
            <div className="icon-container">
              {isPointTypeContainsCopec ? (
                <img
                  src={
                     "https://static.blue.cl/images/map_markers/copec-1.png"
                  }
                  alt="store-icon"
                  className="icon-store"
                />
              ) : (
                <img
                  src={
                    "https://static.blue.cl/images/map_markers/b-blue.png"
                  }
                  alt="store-icon"
                  className="icon-store"
                />
              )}
            </div>
            <span className="text-main cursor-pointer">{point.name}</span>
          </div>
          <div className="d-flex justify-content-end align-items-start">
            <span className="fw-800 text-km color-text-orange ps-1">
              a {point.distance}
            </span>

            {/* toggle content on click */}
            <div id="icon-parent">
              {toggle ? (
                <ChevronDown size={24} className="text-main cursor-pointer" />
              ) : (
                <ChevronRight size={24} className="text-main cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex gap-2 my-2">
        <div className="icon-container">
          <MarkerIcon />
        </div>
        <span className="text-main fw-500">{point.address}</span>
      </div>

      <div className="d-flex gap-2">
        <div className="icon-container">
          <ClockIcon />
        </div>
        <span
          className={`fw-800 text-main border-radius-8px ${
            point.is_24_hours ? "px-2 bg-color-24-7" : ""
          }`}
        >
          {point.is_24_hours ? "Abierto 24/7" : point.schedule}
        </span>
      </div>

      <div className="">
        {toggle && <RenderSchelude point={point} />}

        {/*    <div className="d-flex gap-4 fs-12px justify-content-between fw-700">
          <div className="d-flex align-items-center gap-2">
            <DimensionsIcon />
            <span>{point.dimensions}</span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <WeightIcon />

            <span>{point.weight}</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ListBox;